
.userInfo-layout {
    height: 100%;
    padding: 0 10px;
    position: relative;

    .user-tabs {
        height: 100%;
        position: relative;
    }

    .el-tabs__header {
        margin-bottom: 10px;
    }

    .el-tabs__content {
        height: calc(~"100% - 50px");
        // padding: 0 20px;
    }

    .el-tab-pane {
        height: 100%;
        padding: 5px 10px;
    }

    .scrollbar-wrapper {
        overflow-x: hidden;
    }

    .btn-tools {
        position: absolute;
        right: 0;
        top: 0;
        height: 38px;
        line-height: 38px;
        background-color: #fff;
        z-index: 10;
        padding: 0 10px;
    }
}
