.pane-list-container {
  display: flex;
  position: relative;
  margin-bottom: 20px;
  border: 2px solid #f7f7f7;
  padding-top: 15px;
}
.pane-list-container:hover {
  box-shadow: 0px 1px 6px 1px #dddfe2;
}
.pane-list-container .pane-list-item {
  flex: 1;
  width: calc(100% - 80px);
  padding-right: 10px;
}
.pane-list-container .pane-list-btn {
  width: 80px;
  text-align: right;
  padding-right: 15px;
}
.upload-container {
  width: 260px;
  padding: 0 30px;
  flex: 1;
}
.upload-container .upload-box {
  margin-bottom: 10px;
}
.upload-container .upload-box:last-child {
  margin-bottom: 20px;
}
.upload-container p {
  color: #606266;
  font-weight: normal;
  font-size: 14px;
  line-height: 32px;
}
